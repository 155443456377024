import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, content, button1, button2 } from '../../content/korekta-paliwowa'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import { s, sHtml } from '../../style'
import Button from '../../components/Button'

const KorektaPaliwowa = () => {
  const { lang } = useLangContext()

  const { image, body } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/korekta-paliwowa.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      body: allContentfulKorektaPaliwowa(sort: {order: ASC, fields: createdAt}) {
        nodes {
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Czytaj',
      en: 'Read more',
    },

    action: 'SCROLL',
  }

  const bodyContent = lang === 'pl' ? body?.nodes[0]?.body?.childMarkdownRemark?.html : body?.nodes[1]?.body?.childMarkdownRemark?.html;

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={image.childImageSharp}
        position='bottom'
      />
      <Main
        h={1}
        title={content.title[lang]}
        desc={content.desc[lang]}
      >
        <div css={sHtml}>
          {bodyContent && (
            <div
              dangerouslySetInnerHTML={{
                __html: bodyContent,
              }}
            />
          )}
          <div css={sButtons}>
            <Button
              reactive
              link={button1.link[lang]}
            >
              {button1.label[lang]}
            </Button>
            <div className='spacing' />
            <Button reactive link={button2.link[lang]} targetBlank>
              {button2.label[lang]}
            </Button>
          </div>
        </div>
      </Main>
    </Layout>
  )
}

const sButtons = {
  display: 'flex',
  '.spacing': {
    paddingLeft: '2rem',
  },
  [s.xs]: {
    flexDirection: 'column',
    '.spacing': {
      padding: '1.5rem 0 0 ',
    },
  },
}

export default KorektaPaliwowa
